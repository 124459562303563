<template>
  <c-box>
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="24px"
    >
      ADIME Note
    </c-heading>
    <c-box v-if="!isEmptyReminder">
      <c-text
        font-weight="500"
        font-size="18px"
        line-height="150%"
        color="superDarkGray.900"
      >
        Pengisian ADIME Note
      </c-text>
      <hr v-chakra mt="10px" mb="18px" />
      <c-alert
        border-radius="6px"
        :py="['15px', '30px']"
        :px="['20px', '40px']"
        mb="32px"
        :bg="
          reminder.completed
            ? 'rgba(0, 140, 129, 0.2)'
            : 'rgba(244, 204, 70, 0.2)'
        "
        justify-content="space-between"
        gap="2"
      >
        <c-box>
          <c-text
            font-weight="500"
            font-size="18px"
            line-height="27px"
            color="black"
            mb="4px"
          >
            {{
              reminder.completed
                ? `ADIME Note - ${
                    reminder.program.name
                  } Tanggal ${getFormatDate(reminder.createdAt)}`
                : `Anda belum mengisi ADIME Note untuk Tanggal
                  ${getFormatDate(reminder.createdAt)}`
            }}
          </c-text>
          <c-text
            font-weight="400"
            font-size="16px"
            line-height="24px"
            color="gray"
          >
            {{
              reminder.completed
                ? `Diisi pada: ${getFormatDate(reminder.updatedAt)}`
                : `Klik tombol di samping untuk mengisi ADIME Note untuk Tanggal
                ${getFormatDate(reminder.createdAt)}`
            }}
          </c-text>
        </c-box>
        <c-icon-button
          :min-w="['40px', '50px']"
          :w="['40px', '50px']"
          :h="['40px', '50px']"
          variant-color="orange"
          aria-label="Drink coffee"
          icon="arrow-forward"
          border-radius="50%"
          @click="onBtnForm"
        />
      </c-alert>
    </c-box>

    <c-text
      font-weight="500"
      font-size="18px"
      line-height="150%"
      color="superDarkGray.900"
    >
      History ADIME Note
    </c-text>
    <hr v-chakra mt="10px" mb="18px" />

    <c-grid :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']" gap="6">
      <c-flex
        v-for="(item, index) in histories"
        :key="index"
        border="1px solid #C4C4C4"
        box-sizing="border-box"
        border-radius="12px"
        py="24px"
        px="27px"
        pr="10px"
        :bg="item.isDraft && !item.submitAt ? 'superLightGray.900' : 'white'"
        justify-content="space-between"
        gap="1"
      >
        <c-box>
          <c-text
            font-weight="500"
            font-size="18px"
            line-height="27px"
            color="black"
            mb="4px"
          >
            ADIME Note - {{ item.program.name }} -
            {{ getFormatDate(item.createdAt) }}
          </c-text>
          <c-text
            font-weight="400"
            font-size="18px"
            line-height="27px"
            color="lightGray.900"
          >
            Diisi pada {{ getFormatDate(item.updatedAt) }}
          </c-text>
        </c-box>
        <c-flex v-if="item.canOpen" align="center">
          <c-button p="0" bg="unset" @click="onAdimeNote(item)">
            <c-image
              w="24px"
              h="24px"
              :src="require('@/assets/icon-chevron-right.svg')"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-grid>

    <c-flex v-if="isEmpty" py="80px" px="20px" justifyContent="center">
      <c-box>
        <c-image
          :h="['189px', '300px']"
          :src="require('@/assets/empty-nutritionists.svg')"
          alt="empty"
        />
        <c-text
          mt="41px"
          color="gray.900"
          :fontSize="['14px', '24px']"
          :lineHeight="['21px', '36px']"
          textAlign="center"
        >
          Histori tidak ditemukan
        </c-text>
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import { mapActions, mapState } from "vuex"
import moment from "moment"
import _ from "lodash"
export default {
  name: "AdimeNotePage",
  computed: {
    ...mapState({
      histories: (s) => s.nutriAdimeNote.historyAdimeNotes,
      reminder: (s) => s.nutriAdimeNote.reminderAdimeNote,
    }),
    isEmptyReminder() {
      return _.isEmpty(this.reminder)
    },
    isEmpty() {
      return this.histories.length === 0
    },
  },
  methods: {
    ...mapActions({
      loadAdimeNotes: "nutriAdimeNote/getAdimeNotesByClientId",
      loadReminder: "nutriAdimeNote/getAdimeNotesReminder",
    }),
    getFormatDate(date) {
      moment.locale("id")
      return moment(date).format("D MMMM yyyy")
    },
    onBtnForm() {
      if (this.reminder.completed) {
        this.$router.push({
          name: "nutri.adime-note.form",
          query: { id: this.reminder.adimeNoteId },
        })
      } else {
        this.$router.push({
          name: "nutri.adime-note.form",
          query: { programId: this.reminder.programId },
        })
      }
    },
    onAdimeNote(item) {
      if (item.canOpen) {
        this.$router.push({
          name: "nutri.adime-note.history",
          query: { id: item.id },
        })
      }
    },
  },
  async mounted() {
    this.loadAdimeNotes(this.clientId)
    this.loadReminder(this.clientId)
  },
}
</script>

<style scoped>
.m-tabs {
  border-color: transparent;
  border-bottom-width: 5px;
}
.m-tabs.router-link-active {
  border-bottom-width: 5px;
  border-color: #008c81;
  color: #008c81;
}
</style>
